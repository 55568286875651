/* ./src/index.css */
@tailwind base;

/* Typography */
@layer base {
  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Regular.woff') format('woff'),
      url('./assets/fonts/Poppins-Regular.woff2') format('woff2'),
      url('./assets/fonts/Poppins-Regular.ttf') format('truetype'),
      url('./assets/fonts/Poppins-Regular.svg#PoppinsRegular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Italic.woff') format('woff'),
      url('./assets/fonts/Poppins-Italic.woff2') format('woff2'),
      url('./assets/fonts/Poppins-Italic.ttf') format('truetype'),
      url('./assets/fonts/Poppins-Italic.svg#PoppinsItalic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Medium.woff') format('woff'),
      url('./assets/fonts/Poppins-Medium.woff2') format('woff2'),
      url('./assets/fonts/Poppins-Medium.ttf') format('truetype'),
      url('./assets/fonts/Poppins-Medium.svg#PoppinsMedium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-SemiBold.woff') format('woff'),
      url('./assets/fonts/Poppins-SemiBold.woff2') format('woff2'),
      url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype'),
      url('./assets/fonts/Poppins-SemiBold.svg#PoppinsSemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url('./assets/fonts/Poppins-Bold.woff') format('woff'),
      url('./assets/fonts/Poppins-Bold.woff2') format('woff2'),
      url('./assets/fonts/Poppins-Bold.ttf') format('truetype'),
      url('./assets/fonts/Poppins-Bold.svg#PoppinsBold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  html {
    @apply font-poppins;
  }

  a {
    @apply text-brand-500 hover:text-brand-700 font-medium text-sm leading-5 transition transition-all;
  }
}

/* Tailwind components and Utilities */
@tailwind components;
@tailwind utilities;

/* chart styles */
.apexcharts-tooltip {
  @apply bg-white text-gray-500 border-0 rounded-lg shadow-lg !important;
}

.apexcharts-tooltip .apexcharts-tooltip-title {
  @apply py-2 px-4 bg-gray-100 border-b border-gray-200 !important;
}

.apexcharts-xaxistooltip {
  @apply text-gray-500 border-0 bg-white rounded-lg shadow-lg !important;
}

.apexcharts-xaxistooltip-text {
  @apply font-medium text-sm !important;
}

.apexcharts-xaxistooltip:before,
.apexcharts-xaxistooltip:after {
  @apply border-0 !important;
}

/* SVG map styles */
.svgMap-map-wrapper {
  @apply bg-white !important;
}

.svgMap-map-controls-wrapper {
  @apply shadow-none left-0 bottom-0 !important;
}

.svgMap-map-wrapper .svgMap-control-button {
  @apply rounded-lg border-solid border border-gray-300 hover:bg-gray-100 !important;
}

.svgMap-map-wrapper .svgMap-control-button:first-child {
  @apply mr-2 !important;
}

.svgMap-tooltip {
  @apply bg-white shadow-lg rounded-lg border-0 text-left !important;
}

.svgMap-tooltip
  .svgMap-tooltip-content-container
  .svgMap-tooltip-flag-container {
  @apply inline-block mr-2 text-left border-0 p-0 !important;
}

.svgMap-tooltip
  .svgMap-tooltip-content-container
  .svgMap-tooltip-flag-container
  .svgMap-tooltip-flag {
  @apply inline-block border-0 h-4 p-0 !important;
}

.svgMap-tooltip .svgMap-tooltip-title {
  @apply inline-block pt-2 text-gray-900 font-semibold text-sm !important;
}

.svgMap-tooltip .svgMap-tooltip-content {
  @apply mt-0 !important;
}

.svgMap-tooltip .svgMap-tooltip-content table td {
  @apply text-sm text-left text-gray-500 font-normal !important;
}

.svgMap-tooltip .svgMap-tooltip-content table td span {
  @apply text-sm text-left text-gray-900 font-semibold !important;
}

.svgMap-tooltip .svgMap-tooltip-pointer {
  @apply hidden !important;
}
